"use client";
import { useRef, useState, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";
// import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import Base64Image from "./Base64ImageDisplay";
import ImageGallery from "./ImageGallery";
import QAComponent from "./QA";
import { addUserCount } from "../helper/QAndAFirestoreService";
import FormatText from "./TextFormat";

const libraries = ["places"];

const restApiKey = process.env.REACT_APP_REST_API_KEY;
// "success50258a068b68c6b59229945959c15d4749133f4e65f96e5e0de0e192bf566365lokavo";

export default function MapsLocationDisplay() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [mapCenter, setMapCenter] = useState({
    lat: -7.577385515246401,
    lng: 110.82539729026065,
  });

  const handleMapClick = useCallback((event) => {
    if (event.latLng) {
      // Ensure latLng exists
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMapCenter(newPosition); // Update map center to the clicked position
    }
  }, []);

  const searchBoxRef = useRef(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [surroundingImage, setSurroundingImage] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategories, setShowCategories] = useState(false);
  const [strengthsAnalysis, setStrengthsAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [weaknessAnalysis, setWeaknessAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [opportunitiesAnalysis, setOpportunitiesAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [threatsAnalysis, setThreatsAnalysisText] = useState(
    "Click on StartAnalyze to generate a summary"
  );
  const [recommendSummaryText, setRecommendSummaryText] = useState(
    "Click on StartAnalyze to generate a summary"
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey, // Add your API key here
    libraries,
  });

  const onSearchBoxLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const { geometry } = places[0];
      setMapCenter({
        lat: geometry.location.lat(),
        lng: geometry.location.lng(),
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onPlacesChanged();
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    console.log("Selected category:", category);
    setShowCategories(false);
    surroundingDetails(category); // Run postLocationData after selecting the category
    generateSummaryText(category);
  };

  const startAnalyze = () => {
    setShowCategories(true); // Show category buttons when "Start Analyze" is clicked
  };

  const generateSummaryText = async (category) => {
    setLoadingSummary(true);
    setError(null);
    setStrengthsAnalysisText(null);

    console.log(category);

    const dataSummary = {
      arglatitude: mapCenter.lat,
      arglongitude: mapCenter.lng,
      argMainCategory: `${category}`,
    };

    try {
      const response = await fetch(
        "https://lokavo-backend-dev-306419024546.us-central1.run.app/modelling_results",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-API-KEY": restApiKey,
          },
          body: JSON.stringify(dataSummary),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.message || `Error: ${response.statusText}`
        );
      }

      const result = await response.json();

      const business_recommendation = result.business_recommendation;
      const opportunities = result.opportunities;
      const strengths = result.strengths;
      const threats = result.threats;
      const weakness = result.weaknesses;

      setStrengthsAnalysisText(strengths);
      setWeaknessAnalysisText(weakness);
      setOpportunitiesAnalysisText(opportunities);
      setThreatsAnalysisText(threats);
      setRecommendSummaryText(business_recommendation);

      setResult(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Something went wrong.");
    } finally {
      setLoadingSummary(false);
      setShowSummary(true);
    }
  };

  const surroundingDetails = async (category) => {
    setLoading(true);
    setError(null);
    setApiResponse(null);

    console.log("Using selected category:", category);

    const data = {
      arglatitude: mapCenter.lat,
      arglongitude: mapCenter.lng,
      argcolors: ["white", "white", "white"],
    };

    try {
      const response = await fetch(
        "https://lokavo-backend-dev-306419024546.us-central1.run.app/surroundings_details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-API-KEY": restApiKey,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.message || `Error: ${response.statusText}`
        );
      }

      const result = await response.json();

      const placesData = result.traffic_details.map((item) => ({
        featured_image: item.featured_image,
        name: item.name,
        address: item.address,
      }));

      if (placesData.length > 0) {
        setSurroundingImage(placesData);
      }

      setApiResponse(placesData);

      const vizData = result.viz;
      if (vizData) {
        setImageData(vizData);
      }
      setResult(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const currentUser = localStorage.getItem("email");

  const handleUserCount = async () => {
    setLoading(true); // Start loading

    try {
      // Construct a single document object containing all questions and answers
      const userCount = {
        user: 0,
      };

      await addUserCount(userCount); // Save to Firestore
    } catch (error) {
      // toast.error("Something Went Wrong");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* <APIProvider apiKey={apiKey}> */}
      <div className="flex-grow">
        <form className="max-w-md mx-auto mb-4 mt-8" onSubmit={handleSubmit}>
          <div className="relative">
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                type="search"
                ref={inputRef}
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for places..."
                required
              />
            </StandaloneSearchBox>
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-4 py-2"
            >
              Search
            </button>
          </div>
        </form>

        <div className="h-[80vh] w-full relative z-0 mt-4">
          <GoogleMap
            zoom={15}
            center={mapCenter}
            streetViewControl={false}
            mapContainerStyle={{ height: "100%", width: "100%" }}
            mapTypeControl={false}
            onClick={handleMapClick}
          >
            <Marker position={mapCenter} />
          </GoogleMap>
        </div>

        <div className="flex justify-center items-center mt-4">
          <button
            onClick={
              startAnalyze
              // handleUserCount
            }
            className="bg-[#1E90FF] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white"
            disabled={loading}
          >
            {loading ? "Analyzing..." : "Start Analyze"}
          </button>

          {/* <button
            onClick={logout}
            className="bg-[#1E90FF] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white"
            disabled={loading}
          >
            Logout
          </button> */}
        </div>

        {showCategories && (
          <div className="text-white text-center font-bold">
            <p1>Silikan Pilih Kategori Bisnis Anda Terlebih Dahulu:</p1>
          </div>
        )}

        {showCategories && (
          <div className="flex justify-center gap-4 mt-4">
            {[
              "Rumah Makan",
              "Kafe & Kedai Kopi",
              "Street Food",
              "Gerai Dessert",
              "Wedangan atau Angkringan",
            ].map((category) => (
              <button
                key={category}
                onClick={() => handleCategorySelect(category)}
                className={`px-4 py-2 rounded-md text-white ${
                  selectedCategory === category
                    ? "bg-blue-800"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        )}
      </div>
      {result && imageData && (
        <div className="text-center text-white items-center">
          <h3 className="text-center w-full text-4xl font-bold text-[#1E90FF] pb-4 pt-4">
            Analyze Result:
          </h3>
          {/* <pre>{JSON.stringify(apiResponse, null, 2)}</pre> */}
          <Base64Image base64String={imageData} />

          <ImageGallery places={surroundingImage} />
        </div>
      )}
      {loadingSummary && (
        <h1 className="text-center w-full text-4xl font-bold text-[#1E90FF] pb-4">
          Generating S.W.O.T Analysis, Please Wait a Moment
        </h1>
      )}
      {/* if (!loadingSummary) */}

      {showSummary && (
        <div className="p-16">
          <h1 className="text-center w-full text-4xl font-bold text-[#1E90FF] pb-4">
            s.w.o.t analysis
          </h1>
          <h1 className="w-full text-2xl font-bold text-[#1E90FF] pt-4">
            Strengths Analysis
          </h1>

          <FormatText text={strengthsAnalysis} />

          {/* <p className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2">
            {strengthsAnalysis}
          </p> */}
          <h1 className="w-full text-2xl font-bold text-[#1E90FF] pt-4">
            Weakness Analysis
          </h1>

          <FormatText text={weaknessAnalysis} />
          {/* <p className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2">
            {weaknessAnalysis}
          </p> */}
          <h1 className="w-full text-2xl font-bold text-[#1E90FF] pt-4">
            Opportunies Analysis
          </h1>
          <FormatText text={opportunitiesAnalysis} />
          {/* <p className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2">
            {opportunitiesAnalysis}
          </p> */}
          <h1 className="w-full text-2xl font-bold text-[#1E90FF] pt-4">
            Threats Analysis
          </h1>

          <FormatText text={threatsAnalysis} />
          {/* <p className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2">
            {threatsAnalysis}
          </p> */}
          <h1 className="w-full text-2xl font-bold text-[#1E90FF] pt-4">
            Recommendation
          </h1>
          <FormatText text={recommendSummaryText} />
          {/* <p className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2">
            {recommendSummaryText}
          </p> */}
        </div>
      )}
      {error && (
        <div className="text-center text-red-500 mt-4">
          <h3 className="text-lg font-bold">Error:</h3>
          <p>{error}</p>
        </div>
      )}

      {/* Coming Soon Section */}
      <div className="mt-6 bg-gray-800 rounded-lg shadow-lg p-4">
        <h2 className="text-lg font-bold text-center text-[#1E90FF] ">
          Coming Soon: Sentiment Analysis of Competitors
        </h2>
        <p className="text-md text-center text-white">
          Analyzing competitors' reviews to gain insights into their strengths
          and weaknesses, helping businesses stay competitive.
        </p>
      </div>
      {/* </APIProvider> */}
    </div>
  );
}
