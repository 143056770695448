import React from "react";
import { ReactTyped, Typed } from "react-typed";
import { Link } from "react-scroll";

import KeyFeaturesCarousel from "./KeyFeature";

const Hero = ({ scrollToMaps }) => {
  const keyPoints = [
    {
      title: "Hyper Localized Data Driven Insights",
      description:
        "In-depth analysis of business locations within a 4 km radius, using AI to provide accurate recommendations, such as whether a buffet or café is more suitable for that area.",
    },
    {
      title: "Sentiment Analysis",
      description:
        "AI processes consumer reviews to identify issues (negative reviews) and best practices (positive reviews), helping business owners adjust their strategies to market needs.",
    },
    {
      title: "Business Location Search Platform",
      description:
        "Provides easy access to available locations for rent from various partners, facilitating the search and selection of the ideal business location.",
    },
  ];

  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[96px] w-full h-screen mx-auto text-center flex flex-col">
        <p className="text-[#1E90FF] font-bold p-2">OPTIMIZE YOUR BUSINESS</p>
        <h1 className="md:text-7xl sm:text-6xl text:4xl font-bold md:py-6">
          One Click at a Time
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
            Why LOKAVO ?
          </p>
          <ReactTyped
            className="md:text-5xl sm:text-4xl text-xl font-bold pl-2"
            strings={["Fast", "Flexible", "Accurate", "Easy", "Cheap"]}
            typeSpeed={200}
            backSpeed={200}
            loop
          ></ReactTyped>
        </div>
        <p className="text-[#1E90FF] md:text-2xl text-xl font-bold md:pl-4 pl-2">
          Business Intelligence Platform for F&B in Indonesia
        </p>

        {/* Key Features Section */}
        {/* <div className="mt-6 rounded-lg shadow-lg p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          {keyPoints.map((point, index) => (
            <div key={index} className="flex flex-col">
              <p className="text-lg font-bold text-[#1E90FF] ">
                <strong>{point.title}</strong>
              </p>
              <p className="text-md ">{point.description}</p>
            </div>
          ))}
        </div> */}
        <KeyFeaturesCarousel keyPoints={keyPoints}></KeyFeaturesCarousel>
        <button
          onClick={scrollToMaps}
          className="bg-[#1E90FF] w-[200px] rounded-md font-medium my-6 mx-auto py-3"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Hero;
