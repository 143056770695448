import { useState } from "react";

const KeyFeaturesCarousel = ({ keyPoints }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % keyPoints.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + keyPoints.length) % keyPoints.length);
  };

  return (
    <div className="relative overflow-hidden w-full max-w-2xl mx-auto mt-8 p-8">
        <h1 className="text-center font-bold md:text-2xl text-xl mt-8 ">
          KEY FEATURES
        </h1>
      <div className="flex items-center justify-between">
        <button
          onClick={handlePrev}
          className="bg-[#1E90FF] text-white p-2 rounded-full"
        >
          ◀
        </button>

        <div className="p-8 bg-black shadow-lg rounded-lg flex-1 mx-4 text-center">
          <h3 className="text-2xl font-bold text-[#1E90FF] mb-2">
            {keyPoints[currentIndex].title}
          </h3>
          <p className="text-white">{keyPoints[currentIndex].description}</p>
        </div>

        <button
          onClick={handleNext}
          className="bg-[#1E90FF] text-white p-2 rounded-full"
        >
          ▶
        </button>
      </div>
    </div>
  );
};

export default KeyFeaturesCarousel;
