const Base64Image = ({ base64String = "" }) => {
  // Function to extract and clean the base64 image data safely
  const extractImageData = (str) => {
    if (!str || typeof str !== "string") return "";

    // Locate the 'base64,' part and extract the image data after it
    const startIndex = str.indexOf("base64,") + "base64,".length;
    if (startIndex <= "base64,".length - 1) return "";

    // Clean trailing whitespace or unwanted characters like '==', '=">', etc.
    return str
      .substring(startIndex)
      .trim()
      .replace(/[\s=">]+$/, "");
  };

  const extractedData = extractImageData(base64String);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh", // Full page height
        textAlign: "center", // Center align text
      }}
    >
      <h2 className="md:text-5xl sm:text-6xl text:4xl font-bold">
        Traffic conditions around the location based on the time of day.
      </h2>
      {extractedData ? (
        <img
          src={`data:image/png;base64,${extractedData}`}
          alt="Base64"
          style={{ width: "auto", height: "auto" }}
        />
      ) : (
        <p>Invalid or missing Base64 data.</p>
      )}
    </div>
  );
};

export default Base64Image;
