import React, { useRef } from "react";
import Navbar from "../component/Navbar";
import Hero from "../component/Hero";
import Analytics from "../component/Analytics";
import MapsDisplay from "../component/maps";
import Footer from "../component/PageFooter";
import MapsLocationDisplay from "../component/LocationMap";
import QAComponent from "../component/QA";
import SignIn from './Signin';



export default function Home() {

    const mapsRef = useRef(null); // Create a reference for MapsDisplay

    // Function to scroll to the MapsDisplay section
    const scrollToMaps = () => {
        if (mapsRef.current) {
        mapsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div>

<Navbar></Navbar>
<Hero scrollToMaps={scrollToMaps}></Hero>
<Analytics></Analytics>

<div ref={mapsRef}>
{/* <SignIn></SignIn> */}
    <MapsLocationDisplay></MapsLocationDisplay>   
</div>
<QAComponent></QAComponent>
<Footer></Footer>
</div>
    )
}