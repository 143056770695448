import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/lokavologo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      <div className="w-15 h-15">
        <img src={Logo} alt="logo"></img>
      </div>
      {/* <h1 className="w-full text-3xl font-bold text-[#1E90FF]">LOKAVO</h1> */}
      <ul className="hidden md:flex">
        <button onClick={() => navigate("/Home")} className="p-4">
          Home
        </button>
        <button onClick={() => navigate("/Contact")} className="p-4">
          Contact
        </button>
        <button onClick={() => navigate("/About")} className="p-4">
          About
        </button>
        <button onClick={() => navigate("/FAQ")} className="p-4">
          FAQ
        </button>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#1E90FF] m-4">LOKAVO</h1>
        <ul className="uppercase p-4">
          <li
            onClick={() => navigate("/Home")}
            className="p-4 border-b border-gray-600"
          >
            Home
          </li>
          <li
            onClick={() => navigate("/Contact")}
            className="p-4 border-b border-gray-600"
          >
            Contact
          </li>
          <li
            onClick={() => navigate("/FAQ")}
            className="p-4 border-b border-gray-600"
          >
            FAQ
          </li>
          <li onClick={() => navigate("/About")} className="p-4">
            About
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
