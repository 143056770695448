// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore" ;
import { getAuth, GoogleAuthProvider } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAn_AyQ9M7-T4198VEr3xjbCWJRc9xPgV8",
  authDomain: "lokavo-web-fb01e.firebaseapp.com",
  projectId: "lokavo-web-fb01e",
  storageBucket: "lokavo-web-fb01e.appspot.com",
  messagingSenderId: "5712655144",
  appId: "1:5712655144:web:c4e9a3c5c2b778de67d61d",
  measurementId: "G-2974LNBE7E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { db,auth,provider };