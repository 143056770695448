import React from "react";
import Navbar from "./component/Navbar";
import Hero from "./component/Hero"
import MapsDisplay from './component/maps';
import Analytics from "./component/Analytics";
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import Contact from "./pages/Contact";
import Home from "./pages/HomeP";
import About from "./pages/About";
import Resource from "./pages/Resource";





function App() {
  return (
    <div>

      <BrowserRouter>
  <Routes>
  <Route index element= {<Home/>}></Route>
    <Route path="/home" element={<Home/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/faq" element={<Resource/>}/>
    <Route path="/about" element={<About/>}/>
        </Routes>
        </BrowserRouter>

    </div>
  );
}

export default App;
