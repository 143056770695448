import React from 'react';
import Navbar from './../component/Navbar';
import Footer from '../component/PageFooter';

const FAQ = () => {
  return (
    <div className="bg-black items-center">
      <Navbar />
      
      <div className="bg-white shadow-lg rounded-lg p-8 text-center m-4">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">Frequently Asked Questions</h1>

        {/* FAQ Entries */}
        <div className="text-left">
          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">1. What is Lokavo?</h2>
            <p className="text-gray-600">Lokavo is a business intelligence platform that helps entrepreneurs and business owners analyze their chosen business location and competitor landscape.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">2. Who can use Lokavo?</h2>
            <p className="text-gray-600">Lokavo is designed for entrepreneurs, SMEs, and F&B businesses looking for location insights and competitor analysis in Indonesia.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">3. How does Lokavo help F&B businesses?</h2>
            <p className="text-gray-600">Lokavo provides detailed analysis of the location chosen by the business owner, offering insights that help make better decisions for growth and sustainability.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">4. Is Lokavo available across Indonesia?</h2>
            <p className="text-gray-600">No, currently Lokavo is only available for businesses across Surakarta City, Indonesia.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">5. What industries does Lokavo support?</h2>
            <p className="text-gray-600">Lokavo primarily supports F&B businesses, but its insights can be applied to a variety of industries that need location-based analysis.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">6. Is Lokavo’s platform easy to use?</h2>
            <p className="text-gray-600">Yes, Lokavo’s platform is designed to be intuitive and user-friendly, with minimal technical expertise required to navigate and interpret the data.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">7. Do I need to download any software?</h2>
            <p className="text-gray-600">Yes, Lokavo is an android application that can be downloaded on Google Play Store.</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">8. How long does it take to analyze my chosen location?</h2>
            <p className="text-gray-600">After selecting your business location, Lokavo typically completes the analysis within minutes, giving you detailed insights quickly and easily.</p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default FAQ;
