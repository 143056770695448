import React from "react";
import { FaInstagram, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-black py-8 mt-8">
      <div className="flex justify-center space-x-8">
        {/* Instagram */}
        <a
          href="https://www.instagram.com/lokavo.id/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-3xl hover:text-[#1E90FF]"
        >
          <FaInstagram />
        </a>

        {/* LinkedIn */}
        <a
          href="https://www.linkedin.com/company/lokavo-id/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-3xl hover:text-[#1E90FF]"
        >
          <FaLinkedin />
        </a>

        {/* Email */}
        <a
          href="mailto:lokavo.localventures@gmail.com;"
          className="text-white text-3xl hover:text-[#1E90FF]"
        >
          <FaEnvelope />
        </a>
      </div>

      <div className="text-center mt-4 text-white">
        <p>&copy; 2024 Lokavo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
