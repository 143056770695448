import React from "react";

// const FormatText = (text) => {
//   // Split text into sections based on '**' markers for headings
//   const sections = text
//     .split(/\*\*(.*?)\*\*/)
//     .filter((section) => section.trim());

//   // Map through sections to create formatted elements
//   return sections.map((section, index) => {
//     // Odd indexes will be section titles, even will be content
//     if (index % 2 === 1) {
//       // Add numbered titles as needed based on the heading text
//       const heading = `${index / 2 + 1}. ${section}`;
//       return <h3 key={index}>{heading}</h3>;
//     } else {
//       // Add formatted lists for the content under each title
//       const items = section.split("* Contoh:");
//       const description = items[0];
//       const examples = items[1]
//         ? items[1]
//             .split(",")
//             .map((example, i) => <li key={i}>{example.trim()}</li>)
//         : null;

//       return (
//         <div key={index}>
//           <p>{description}</p>
//           {examples && (
//             <ul>
//               <strong>Contoh:</strong> {examples}
//             </ul>
//           )}
//         </div>
//       );
//     }
//   });
// };

// export default FormatText;

const FormatText = ({ text = "" }) => {
  // Only proceed if text is available
  if (!text) return null;

  // Split text by '**' to detect headings and content
  const sections = text
    .split(/\*\*(.*?)\*\*/)
    .filter((section) => section.trim());

  // Map through sections to format each as a heading or content
  return sections.map((section, index) => {
    if (index % 2 === 1) {
      // Odd index: This is a heading section
      return (
        <h3
          className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2"
          key={index}
        >
          {section}
        </h3>
      );
    } else {
      // Even index: This is content under the heading
      // Split by '* ' to detect bullet points
      const items = section
        .split("*")
        .map((item) => item.trim())
        .filter((item) => item); // Remove any empty strings

      // Separate the description from example points
      const description = items[0]; // First item as main description
      const examples = items.slice(1); // Remaining items as bullet points

      return (
        <div
          key={index}
          className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2"
        >
          <p>{description}</p>
          {examples.length > 0 && (
            <ul className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2">
              {examples.map((example, i) => (
                <li
                  key={i}
                  className="text-white md:text-2xl text-xl font-semibold md:pl-4 pl-2"
                >
                  {example}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }
  });
};

export default FormatText;
