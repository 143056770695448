import React from "react";
import Location from "../assets/location.png";
import Needs1 from "../assets/needs_1.png";
import Needs2 from "../assets/needs_2.png";
import Benefit from "../assets/benefit.png";

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img src={Location} alt="location"></img>

        <div className="text-center py-5">
          <h1 className="w-full text-6xl font-bold text-[#1E90FF] pb-4">
            80% of F&B businesses fail within the first five years.
          </h1>
          <p1 className="font-semibold">
            Choosing the right location should be considered from the very
            beginning before starting a business and is something that needs to
            be carefully attended to.
            {/* "Menentukan lokasi yang tepat harus dipertimbangkan sejak awal
            sebelum bisnis dimulai dan menjadi hal yang perlu diperhatikan
            dengan baik" */}
          </p1>
          <p className="font-extrabold">Elisabeth Saragih</p>
        </div>
      </div>

      <div className="text-center m-24">
        <h1 className="w-full text-6xl font-bold text-[#1E90FF] pb-4">
          What Would They Need?
        </h1>
      </div>

      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
        <img src={Needs1} alt="needs"></img>

        <img src={Needs2} alt="needs"></img>
      </div>

      <div className="text-center md:m-24 mt-8">
        <h1 className="w-full text-6xl font-bold text-[#1E90FF] pb-8">
          Why LOKAVO ?
        </h1>

        <img src={Benefit} alt="benefit"></img>

        <h1 className="w-full text-6xl font-semibold text-[#1E90FF] pb-4 mt-32">
          Getting started is easy! Just enter your F&B location, and we'll
          handle the analysis for you in no time.
        </h1>
      </div>
    </div>
  );
};

export default Analytics;
